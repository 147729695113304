export const apiEndpoints = {
  getForecast: 'api/v1/CityTable/summary?version=',
  getContinentsList: 'api/v1/continent/continent-lists/?version=',
  getRegionsList: 'api/v1/region/region-lists/{continentId}?version=',
  getPrimaryLensList: 'api/v1/PrimaryLens/get-lens',
  getRunDates: 'api/v1/RunDate/runDate-lists/{continentId}',
  getHourlyForecastRunDates:
    'api/v1/Rundate/forecast-diff/hourly/{continentId}',
  getHourlyForecastValidDates: 'api/v1/Rundate/forecast/validdates',
  getNewsFeed: 'api/v1/news',
  getDailyForecastValues: 'api/v1/location/dailyforecastvalue',
  getHourlyForecastValues: 'api/v1/location/hourlyforecastvalue',
  getDailyForecastDifference: 'api/v1/location/dailyforecastdiff',
  getHourlyForecastDifference: 'api/v1/location/hourlyforecastdiff',
  getForecastAnalysis: 'api/v1/forecastanalysis',
  getCitiesList: 'api/v1/CityTable/getCities/{continent}/{region}',
  getContinentCitiesList: 'api/v1/CityTable/city-lists/{continent}',
  getForecastCommentarySummaryData:
    'api/v1/Graphics/period-forecasts/{region}/{forecastType}?version=',
  getForecastCommentaryDetailedDiscussion:
    'api/v1/Discussions/period-forecast-discussions/{continent}/{region}?version=',
  getForecastCommentaryRegionList:
    '/assets/mock/forecast-regions-list-{continent}.json',
  getLocations: 'api/v1/Discussions/period-forecast-discussions/regions',
  getDetailedDiscussionsContinents:
    '/assets/mock/forecast-detailed-discussions-continents.json',
  getCompareLocationData: 'api/v1/CompareLocation/getCompareLocation?version=',
  exportByUrlAsCSV: 'api/v1/{url}/export-csv',
  getObservationTemp:
    'api/v1/CityTable/obs/{continentId}/{regionId}/{isCustom}/{tempUnit}',
  getNormalTemp: 'api/v1/CityTable/normal',
  forecastAnalysisCSVExport: 'CityViewAnalysis/forcastAnalysis',
  forecastValuesDailyCSVExport: 'api/v1/Location/dailyforecastvalue/download',
  forecastValuesHourlyCSVExport: 'api/v1/Location/hourlyforecastvalue/download',
  getSearchLocations: 'api/v1/CityTable/search/{value}',
  getFRiskIndexData: 'api/v1/location/frisk/index',
  getHistoricalData: 'api/v1/CityTable/summary/historical/popup',
  getThresholdExceedanceData: 'api/v1/Location/frisk/thresholdexceedance',
  getProbabilityAnalysisMinMaxData: 'api/v1/Location/frisk/probabilityanalysis',
  getProbabilityAnalysisPrecipData:
    'api/v1/Location/frisk/probabilityanalysis/precip',
  getForecastPoints: 'api/v1/Location/frisk/thresholdexceedance/fcstpoints',
  getBlurbs: '/api/v1/Blurb/AllBlurbs',
  getBlurb: '/api/v1/Blurb/{id}',
  getBlurbExperts: '/api/v1/Blurb/experts',
  updateUserLastVisitTime: '/api/v1/Blurb/UpdateUserLastVisit',
  getNumberOfUnreadBlurbs: '/api/v1/Blurb/numberOfUnread',
  getBlogs: '/api/v1/Blog/blogs',
  updateBlog: '/api/v1/Blog/',
  getBlogAuthors: '/api/v1/Blog/authors',
  getBlogCategories: '/api/v1/Blog/categories',
  getNumberOfUnreadBlogs: '/api/v1/Blog/numberOfUnread',
  getThresholdExeedanceGraphInfo:
    'api/v1/Location/frisk/thresholdexceedance/graphInfo',
  getProbilityAnalysisGraphInfo:
    'api/v1/Location/frisk/probabilityanalysis/graphInfo',
  getIsoCountryDailyAg2Forecast: 'api/v1/WeightedIsoCountry/ag2forecast/daily',
  getIsoCountryPeriodAg2Forecast:
    'api/v1/WeightedIsoCountry/ag2forecast/period',
  getIsoCountryModelDailyForecast:
    'api/v1/WeightedIsoCountry/modelforecast/daily',
  getIsoCountryModelPeriodForecast:
    'api/v1/WeightedIsoCountry/modelforecast/period',
  getWeightedIsoCountryRegions: 'api/v1/WeightedIsoCountry/regions/{continent}',
  getWeightedIsoCountryRunDate:
    'api/v1/WeightedIsoCountry/modelforecast/rundate/{continent}',
  getDailyForecastValueHistoricalData:
    'api/v1/Location/dailyforecastvalue/historicalPopup',
  getUsGasBcfCompanyDropdown: 'api/v1/WeightedBcf/companies',
  getUsGasBcfModelsRun: 'api/v1/WeightedBcf/modelrun',
  getUsGasBcfAg2DailyForecast: 'api/v1/WeightedBcf/ag2forecast/daily',
  getUsGasBcfAg2PeriodForecast: 'api/v1/WeightedBcf/ag2forecast/period',
  getWeightedAg2DailyForecast: 'api/v1/WeightedDegreeDays/ag2forecast/daily',
  getWeightedAg2PeriodForecast: 'api/v1/WeightedDegreeDays/ag2forecast/period',
  getWeightedDegreeDaysRegions: 'api/v1/WeightedDegreeDays/regions/{continent}',
  getWeightedUsGasBcfRegions: 'api/v1/WeightedBcf/regions/{continent}',
  getWeightedDegreeModelForecastDropdown:
    'api/v1/WeightedDegreeDays/modelforecast/weighted/{continent}',
  getWeightedDegreeRunDate:
    'api/v1/WeightedDegreeDays/modelforecast/rundate/{continent}',
  getUsGasBcfRunDate: 'api/v1/WeightedBcf/modelforecast/rundate',
  getWeightedDegreeDailyModelForecast:
    'api/v1/WeightedDegreeDays/singleforecast/daily',
  getWeightedDegreePeriodModelForecast:
    'api/v1/WeightedDegreeDays/singleforecast/period',
  geWeightedModelsRun: 'api/v1/weighted/modelrun',
  getWeightedDegreeModelRunDailyForecast:
    '/assets/mock/weighted-degree-days-model-run-time-daily-forecast.json',
  getWeightedDegreeModelRunPeriodForecast:
    '/assets/mock/weighted-degree-days-model-run-time-period-forecast.json',
  getWeightedDegreeModelRunTimeDailyForecast:
    'api/v1/WeightedDegreeDays/modelforecast/weighted/daily',
  getWeightedDegreeModelRunTimePeriodForecast:
    'api/v1/WeightedDegreeDays/modelforecast/weighted/period',
  getUsGasBcfSingleRuntimeDailyForecast:
    'api/v1/WeightedBcf/singleforecast/daily',
  getIsoCountrySingleDailyModelForecast:
    'api/v1/WeightedIsoCountry/singleforecast/daily',
  getIsoCountrySinglePeriodModelForecast:
    'api/v1/WeightedIsoCountry/singleforecast/period',
  getIsoCountryAg2ForecastGraph: 'api/v1/WeightedIsoCountry/ag2forecast/graph',
  getUsGasBcfSingleRuntimePeriodForecast:
    'api/v1/WeightedBcf/singleforecast/period',
  getUsGasBcfModelRunTimeDailyForecast:
    'api/v1/WeightedBcf/modelforecast/daily',
  getUsGasBcfModelRunTimePeriodForecast:
    'api/v1/WeightedBcf/modelforecast/period',
  weightedISOCountryAg2ForecastCSVExport:
    'api/v1/Location/dailyforecastvalue/download',
  weightedDegreeDaysAg2ForecastCSVExport:
    'api/v1/WeightedDegreeDays/ag2forecast/download',
  weightedDegreeDaysModelSingleForecastCSVExport:
    'api/v1/WeightedDegreeDays/singleforecast/download',
  weightedDegreeDaysISOCountryAG2ForecastCSVExport:
    'api/v1/WeightedIsoCountry/ag2forecast/download',
  weightedDegreeDaysBCFAG2ForecastCSVExport:
    'api/v1/WeightedBcf/ag2forecast/download',
  weightedDegreeDaysBCFSingleForecastCSVExport:
    'api/v1/WeightedBcf/singleforecast/download',
  weightedISOCountryModelRunTimeForecastCSVExport:
    'api/v1/WeightedIsoCountry/modelforecast/download',
  weightedDegreeDaysModelRunTimeForecastCSVExport:
    'api/v1/WeightedDegreeDays/modelforecast/download',
  weightedBcfModelRunTimeForecastCSVExport:
    'api/v1/WeightedBcf/modelforecast/download',
  weightedDegreeDaysISOCountrySingleForecastCSVExport:
    'api/v1/WeightedIsoCountry/singleforecast/download',
  emailNotifications: 'api/v1/Notification/notification-email',
  notificationGroupInfo: 'api/v1/Notification/notification-group-info',
  getNotificationList: 'api/v1/Notification/notification-list',
  getAlertList: 'api/v1/Notification/alert-list',
  emailNotificationsWithEmail: 'api/v1/Notification/notification-email/{email}',
  getAllDashboards: 'api/v1/Dashboard/AllDashboards',
  getDashboard: 'api/v1/Dashboard',
  createDashboard: 'api/v1/Dashboard/new',
  deleteDashboardById: 'api/v1/Dashboard',
  updateDashboard: 'api/v1/Dashboard/edit',
  getCustomLists: 'api/v1/CustomList/profile-custom-lists',
  getSummaryRunDates: 'api/v1/RunDate/rundate-lists',
  getObsTimeStamp: 'api/v1/RunDate/obs-date',
  getPDFProduct: 'api/v1/Documents/pdf-product/{productId}/{continent}',
  getSubSeasonal: 'api/v1/StaticGraphics/subseasonal',
  getRpmGraphics: 'api/v1/StaticGraphics/renewables-graphics',
  getStaticGraphicsSubregions:
    'api/v1/StaticGraphics/subregions/{forecastType}/{continent}',
  getForecastVerification:
    'api/v1/ForecastVerifications/forecast-verifications/{continent}/{region}/{tempUnit}/{windUnit}',
  updateLastSeenNotification: 'api/v1/Notification/update-alert-state',
  getFreshLook: 'api/v1/FreshLook/freshlook',
  getFreshLookPools: 'api/v1/FreshLook/freshlook/pools',
  getFreshLookLatestRunDates: 'api/v1/FreshLook/freshlook/latest-run-dates',
  getFreshLookPrimaryRunDates: 'api/v1/FreshLook/freshlook/primary-run-dates',
  getNaRenewablesHeatMap: 'api/v1/Windcast/table',
  getNaRenewablesHeatMapForecast: 'api/v1/Windcast/run-dates',
  getNaRenewablesHeatMapCSV: 'api/v1/Documents/windcast-table/download',
  getRenewablesTableRunDates: 'api/v1/Renewables/renewables-run-dates',
  getEnhancedDataServicesApiKeys: 'api/v1/EnhancedData/api-keys',
  getForecastEvolutionTable:
    'api/v1/ForecastEvolution/forecast-evolution/table',
  getForecastEvolutionTableCSV:
    'api/v1/Documents/forecast-evolution-table/download',
  getForecastEvolutionTableDailyEvolutionChartNormals:
    'api/v1/ForecastEvolution/forecast-evolution-normals/pop-up',
};
