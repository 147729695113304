import { IContinent } from '../../../../shared/components/src/lib/location-selection/location.interface';
import {
  WeightedWidgetPanelState,
  WidgetPanelState,
  IRangeSelectorOption,
} from '../interfaces';
import { WidgetTypes } from './widget-types';
import {
  CategoryValues,
  ContinentNames,
  DailyPeriodValues,
  ForecastValues,
  RunTimeModelValues,
} from '../enums';

export const TempUnitC = 'C';
export const ListOfContinents: IContinent[] = [
  { name: 'North America', id: 'NA' },
  { name: 'Europe', id: 'EUR' },
  { name: 'Asia', id: 'ASIA' },
  { name: 'Mexico', id: 'MEX' },
];
export const DeFaultWidgetPanelState: WidgetPanelState = {
  selectedContinent: '',
  selectedRegion: '',
  selectedCity: '',
  selectedSiteId: '',
  selectedSecondaryLens: '',
  diffPriorValue: false,
  showDecimalValue: false,
  selectedSumTableSize: 'full',
};

export const DefaultWeightedWidgetPanelState: WeightedWidgetPanelState = {
  selectedCategory: CategoryValues.iso,
  selectedContinent: '',
  selectedRegionId: '',
  selectedRegionName: '',
  selectedForecast: ForecastValues.ag2,
  selectedClimatology: 'AvgYrThirty',
  selectedTableSize: 'full',
  selectedRunTimeModel: RunTimeModelValues.runTime,
  selectedRunTimeData: '',
  selectedParameterValue: '',
  selectedDailyPeriod: DailyPeriodValues.daily,
  diffPriorValue: false,
  showDecimalValue: false,
  normalValue: false,
  biasCorrected: false,
};

export const WidgetLabelConfig: Record<string, string> = {
  [WidgetTypes.heatMap]: 'Summary Table',
  [WidgetTypes.renewables]: 'Renewables',
  [WidgetTypes.analysis]: 'Forecast Analysis',
  [WidgetTypes.weighted]: 'Weighted Forecast Table',
  [WidgetTypes.model]: 'Model Data',
  [WidgetTypes.weightedGraph]: 'Weighted Forecast Graph',
  [WidgetTypes.weightedGraphDifferences]: 'Weighted Forecast Graph Differences',
  [WidgetTypes.demand]: 'Demand',
};

export const WeightedContinentList = [
  { text: ContinentNames.na, id: 'NA' },
  { text: ContinentNames.eur, id: 'EUR' },
  { text: ContinentNames.asia, id: 'ASIA' },
];

export const DefaultLocation: Record<string, { [key: string]: string }> = {
  [CategoryValues.iso]: {
    [ContinentNames.na]: 'MISO',
    [ContinentNames.eur]: 'ITALY',
    [ContinentNames.asia]: 'CHINA',
  },
  [CategoryValues.degree]: {
    [ContinentNames.na]: 'MOUNTAIN',
    [ContinentNames.eur]: 'NORWAY',
    [ContinentNames.asia]: 'CHINA',
  },
};

export const RANGE_SELECTOR_OPTIONS: Array<IRangeSelectorOption> = [
  {
    label: 'Previous 24 Hrs',
    value: {
      min: -1,
      max: 0,
    },
  },
  {
    label: '24 Hrs',
    value: {
      min: 0,
      max: 1,
    },
  },
  {
    label: '48 Hrs',
    value: {
      min: 0,
      max: 2,
    },
  },
  {
    label: '1-7 Days',
    value: {
      min: 0,
      max: 7,
    },
  },
  {
    label: '1-15 Days',
    value: {
      min: 0,
      max: 15,
    },
  },
  {
    label: 'Full Range',
    value: {
      min: -6,
      max: 15,
    },
  },
];
