export enum ForecastValues {
  ag2 = 'ag2Forecast',
  model = 'modelForecast',
}

export enum RunTimeModelValues {
  runTime = 'modelRunTime',
  single = 'singleModel',
}

export enum DailyPeriodValues {
  daily = 'daily',
  period = 'period',
}

export enum CategoryValues {
  iso = 'iso',
  degree = 'degree',
}

export enum ContinentNames {
  na = 'North America',
  eur = 'Europe',
  asia = 'Asia',
}

export enum UnitType {
  F = 'F',
  C = 'C',
}
