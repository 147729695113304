<div class="weighted-widget-panel-container">
  <div class="labels-column">
    <div *ngFor="let label of labels" class="label">{{ label }}</div>
    <!-- <div class="label ens-spread-label">Ensemble Spread</div> -->
  </div>
  <div class="parameter-column">
    <div (click)="openWeightedLocationSelector()" class="location">
      {{ getContinent(selectedContinent) }} > {{ selectedSiteId }}
    </div>
    <div class="parameter">
      <firebird-web-model-weighted-forecast-details-dropdown
        [options]="modelWeightedForecastOptions"
        [modelWeightedForecast]="modelWeightedForecast"
        [isWidget]="true"
        (modelWeightedForecastChanged)="detailsChanged($event)"
      ></firebird-web-model-weighted-forecast-details-dropdown>
    </div>
    <!-- <div class="show-bias">
      <firebird-web-checkbox class="bias-checkbox" [checked]="biasCorrect" labelKey="Bias Corrected"
        (changeEvent)="toggleBias($event)"></firebird-web-checkbox>
    </div> -->
    <!-- <div class="ensemble-spread">
      <firebird-web-dropdown
        class="multi-dd-start widget-panel"
        [value]="selectedEnsembleSpread?.value || ''"
        [options]="ensembleSpreads"
        (changeEvent)="ensSpreadChange($event)"
        type="variation-five"
      >
      </firebird-web-dropdown>
    </div> -->
  </div>
</div>
<hr class="spacer" />
<div class="model-run-selection-container">
  <div class="title">Select Models and Model Runs</div>
  <div class="selection-container">
    <div class="select-button">Select All</div>
    |
    <div class="select-button">Select None</div>
  </div>
  <div class="ag2-forecast-container">
    <button
      class="ag2-forecast-button"
      [class.selected]="ag2_latest_selected"
      (click)="ag2_latest_selected = !ag2_latest_selected"
    >
      AG2 Latest
    </button>
    <button
      class="ag2-forecast-button"
      [class.selected]="ag2_previous_selected"
      (click)="ag2_previous_selected = !ag2_previous_selected"
    >
      AG2 Previous
    </button>
  </div>
  <div class="model-run-selection-section">
    <div>
      <div class="title">Model Runs</div>
      <div class="model-runs-section">
        <div>
          <firebird-web-checkbox
            [checked]="latest_selected"
            labelKey="Latest"
            (changeEvent)="checkChanged('latest', $event)"
          ></firebird-web-checkbox>
        </div>
        <div class="selector-padding">
          <firebird-web-checkbox
            [checked]="latest_minus6_selected"
            labelKey="Previous Run (Latest -6hrs)"
            (changeEvent)="checkChanged('latest_minus6', $event)"
          ></firebird-web-checkbox>
        </div>
        <div class="selector-padding">
          <firebird-web-checkbox
            [checked]="latest_minus12_selected"
            labelKey="Previous Run (Latest -12hrs)"
            (changeEvent)="checkChanged('latest_minus12', $event)"
          ></firebird-web-checkbox>
        </div>
        <div class="selector-padding">
          <firebird-web-checkbox
            [checked]="latest_minus24_selected"
            labelKey="Previous Run (Latest -24hrs)"
            (changeEvent)="checkChanged('latest_minus24', $event)"
          ></firebird-web-checkbox>
        </div>
        <hr />
        <div class="model-run-latest-previous-section">
          <div>
            <div>
              <firebird-web-checkbox
                [checked]="latest_0_selected"
                labelKey="Latest 00Z"
                (changeEvent)="checkChanged('latest_0', $event)"
              ></firebird-web-checkbox>
            </div>
            <div class="selector-padding">
              <firebird-web-checkbox
                [checked]="latest_6_selected"
                labelKey="Latest 06Z"
                (changeEvent)="checkChanged('latest_6', $event)"
              ></firebird-web-checkbox>
            </div>
            <div class="selector-padding">
              <firebird-web-checkbox
                [checked]="latest_12_selected"
                labelKey="Latest 12Z"
                (changeEvent)="checkChanged('latest_12', $event)"
              ></firebird-web-checkbox>
            </div>
            <div class="selector-padding">
              <firebird-web-checkbox
                [checked]="latest_18_selected"
                labelKey="Latest 18Z"
                (changeEvent)="checkChanged('latest_18', $event)"
              ></firebird-web-checkbox>
            </div>
          </div>
          <div>
            <div>
              <firebird-web-checkbox
                [checked]="previous_0_selected"
                labelKey="Previous 00Z"
                (changeEvent)="checkChanged('previous_0', $event)"
              ></firebird-web-checkbox>
            </div>
            <div class="selector-padding">
              <firebird-web-checkbox
                [checked]="previous_6_selected"
                labelKey="Previous 06Z"
                (changeEvent)="checkChanged('previous_6', $event)"
              ></firebird-web-checkbox>
            </div>
            <div class="selector-padding">
              <firebird-web-checkbox
                [checked]="previous_12_selected"
                labelKey="Previous 12Z"
                (changeEvent)="checkChanged('previous_12', $event)"
              ></firebird-web-checkbox>
            </div>
            <div class="selector-padding">
              <firebird-web-checkbox
                [checked]="previous_18_selected"
                labelKey="Previous 18Z"
                (changeEvent)="checkChanged('previous_18', $event)"
              ></firebird-web-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="models-section">
      <div class="title">Models</div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="ecmwf_op_selected"
          labelKey="ECMWF OP"
          (changeEvent)="checkChanged('ecmwf_op', $event)"
        ></firebird-web-checkbox>
      </div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="ecmwf_ens_selected"
          labelKey="ECMWF ENS"
          (changeEvent)="checkChanged('ecmwf_ens', $event)"
        ></firebird-web-checkbox>
      </div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="gfs_op_selected"
          labelKey="GFS OP"
          (changeEvent)="checkChanged('gfs_op', $event)"
        ></firebird-web-checkbox>
      </div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="gfs_ens_selected"
          labelKey="GFS ENS"
          (changeEvent)="checkChanged('gfs_ens', $event)"
        ></firebird-web-checkbox>
      </div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="gem_op_selected"
          labelKey="GEM OP"
          (changeEvent)="checkChanged('gem_op', $event)"
        ></firebird-web-checkbox>
      </div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="gem_ens_selected"
          labelKey="GEM ENS"
          (changeEvent)="checkChanged('gem_ens', $event)"
        ></firebird-web-checkbox>
      </div>
    </div>
    <div class="models-exclude-section">
      <div class="title">Exclude 6z and 18z</div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="ecmwf_op_exclude_6_18_selected"
          [disabled]="!ecmwf_op_selected"
          (changeEvent)="checkChanged('ecmwf_op_exclude_6_18', $event)"
        ></firebird-web-checkbox>
      </div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="ecmwf_ens_exclude_6_18_selected"
          [disabled]="!ecmwf_ens_selected"
          (changeEvent)="checkChanged('ecmwf_ens_exclude_6_18', $event)"
        ></firebird-web-checkbox>
      </div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="gfs_op_exclude_6_18_selected"
          [disabled]="!gfs_op_selected"
          (changeEvent)="checkChanged('gfs_op_exclude_6_18', $event)"
        ></firebird-web-checkbox>
      </div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="gfs_ens_exclude_6_18_selected"
          [disabled]="!gfs_ens_selected"
          (changeEvent)="checkChanged('gfs_ens_exclude_6_18', $event)"
        ></firebird-web-checkbox>
      </div>
    </div>
    <div class="normals-section">
      <div class="title">Normals</div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="normals_30_selected"
          labelKey="30yr Avg"
          (changeEvent)="checkChanged('normals_30', $event)"
        ></firebird-web-checkbox>
      </div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="normals_10_selected"
          labelKey="30yr Avg"
          (changeEvent)="checkChanged('normals_10', $event)"
        ></firebird-web-checkbox>
      </div>
      <div class="selector-padding">
        <firebird-web-checkbox
          [checked]="last_year_obs_selected"
          labelKey="Last Year Obs"
          (changeEvent)="checkChanged('last_year_obs', $event)"
        ></firebird-web-checkbox>
      </div>
    </div>
  </div>
</div>
<div class="close-save">
  <span (click)="onSave()" class="save">Save</span>
  <span (click)="onClose()" class="cancel">X-Cancel</span>
</div>
