import { Injectable } from '@angular/core';
import {
  OAuthService,
  OAuthErrorEvent,
  AuthConfig,
  TokenResponse,
} from 'angular-oauth2-oidc';
import { environment } from '@firebird-web/shared-config';
import { AuthInfo } from './models/auth-info.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authCodeFlowConfig: AuthConfig = {
    issuer: environment.identityServer,
    tokenEndpoint: environment.identityServer + '/oauth/token',
    redirectUri: window.location.origin + '/auth/callback/signin',
    clientId: environment.clientid,
    responseType: 'code',
    scope: 'openid profile offline_access ag2-trader-app',
    showDebugInformation: true,
    postLogoutRedirectUri: window.location.origin + '/auth/callback/logout',
  };

  private LOCK_KEY = 'refresh_lock';
  private LOCK_TIMEOUT = 30000; // 30 seconds

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  private authInfo: AuthInfo;
  private user: string;
  private sub: string;

  private init$ = new BehaviorSubject<boolean>(false);

  constructor(private oauthService: OAuthService) {}

  public initialize(): Promise<void> {
    this.oauthService.configure(this.authCodeFlowConfig);
    this.oauthService.setStorage(localStorage);
    return new Promise((resolve, reject) => {
      this.oauthService
        .loadDiscoveryDocumentAndTryLogin()
        .then(() => {
          if (!this.oauthService.hasValidAccessToken()) {
            // If there's no valid access token, we attempt to initiate the code flow.
            // initCodeFlow does not return a promise, still need to resolve after it has been called
            // to ensure that the APP_INITIALIZER doesn't complete prematurely.
            const state = window.location.pathname + window.location.search;
            this.oauthService.initCodeFlow(state);
          }
          this.oauthService.loadUserProfile().then((data: any) => {
            this.user = data.info.given_name + ' ' + data.info.family_name;
            this.sub = data.info.sub;
            this.init$.next(true);
            resolve();
          });
        })
        .catch((error) => {
          console.error('OAuthService initialization error:', error);
          reject(error); // Reject the promise on error
        });

      // Handle OAuthService events
      this.oauthService.events.subscribe((event) => {
        if (event instanceof OAuthErrorEvent) {
          console.error('OAuth error event:', event);
        } else {
          console.log('OAuth event:', event);
        }
      });

      // Setup automatic silent refresh
      this.oauthService.setupAutomaticSilentRefresh();
    });
  }

  hasInitialized(): Observable<boolean> {
    return this.init$.asObservable();
  }
  // Initiate the login process
  public login() {
    this.oauthService.initLoginFlow();
  }

  // Log out and clear all session information
  public logout(): void {
    this.oauthService.logOut();
  }

  public hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  // Check if the user is authenticated
  public isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  // Get the token if needed for manual HTTP requests
  public getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public getRefreshToken(): string {
    return this.oauthService.getRefreshToken();
  }

  public getIdentityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  public refreshToken(): Promise<TokenResponse> {
    // Wrap the entire locks.request call in a new Promise
    return new Promise<TokenResponse>((resolve, reject) => {
      navigator.locks.request('refreshTokenLock', async (lock) => {
        // This code executes only when the lock is acquired.
        try {
          // Attempt to refresh the token
          const response = await this.oauthService.refreshToken();
          console.log('Token refreshed successfully.');
          resolve(response); // Resolve the outer promise with the response
        } catch (error) {
          console.error('Error refreshing token:', error);
          reject(error); // Reject the outer promise with the error
        }
      });
    });
  }

  public getAccessTokenExpiration(): number {
    return this.oauthService.getAccessTokenExpiration();
  }

  public getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  public loadUserProfile(): Promise<object> {
    return this.oauthService.loadUserProfile();
  }

  public getUsername() {
    return this.user;
  }

  public getUserId() {
    return this.sub;
  }

  public getState() {
    return this.oauthService.state;
  }
}
